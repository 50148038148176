import './App.css';
import { useFetch } from "react-async"

const APIEndPoint = 'https://4pcgpiam7a.execute-api.us-west-2.amazonaws.com/v1/hello'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {APIEndPoint.startsWith('http') &&
            <APIResult />
        }
      </header>
    </div>
  );
}

const APIResult = () => {
  const { data, error } = useFetch(APIEndPoint, {
    headers: { accept: "application/json" },
  })
  if (error) return <p>{error.message}</p>
  if (data) return <p>{data.message}</p>
  return null
}

export default App;
